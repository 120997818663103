/* text css */
@keyframes positionUpAnimation {
  0% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(var(--bounce));
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.positionUp {
  animation: positionUpAnimation 1s ease-in-out;
  animation-delay: calc(0.05s * var(--index));
  animation-fill-mode: forwards;
}

@keyframes positionLeftAnimation {
  0% {
    transform: translateX(150px);
  }
  50% {
    transform: translateX(var(--bounce));
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.positionLeft {
  animation: positionLeftAnimation 0.5s ease-in-out;
  animation-delay: calc(0.05s * var(--index));
  animation-fill-mode: forwards;
}
@keyframes trackingAnimation {
  0% {
    transform: translateY(40px);
    letter-spacing: -0.5em;
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    letter-spacing: -0.1em;
    opacity: 1;
  }
}

@keyframes trackingExpand {
  0% {
    letter-spacing: -0.1em;
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    letter-spacing: 0.02em;
    transform: translateY(0px);
    opacity: 1;
  }
}
.tracking {
  animation: trackingAnimation 0.1s forwards,
    trackingExpand 0.1s ease-in forwards;
  animation-delay: calc(0.05s * var(--i)), 0.9s;
}

@keyframes scaleDownAnimation {
  0% {
    transform: scale(1.5);
  }
  30% {
    transform: scale(0.7);
    opacity: 1;
  }
  70% {
    transform: scale(1.2);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.scaleDown {
  animation: scaleDownAnimation 1s ease-in-out forwards;
  animation-delay: calc(0.05s * var(--index));
}

@keyframes scaleUpAnimation {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(1.2);
    opacity: 1;
  }
  60% {
    transform: scale(0.7);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.scaleUp {
  animation: scaleUpAnimation 1s ease-in-out forwards;
  animation-delay: calc(0.05s * var(--index));
}

/* btn css */

.btn {
  width: 320px;
  height: 50px;
  border: 1px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  transition: 1s;
}

.btn::before,
.btn::after {
  position: absolute;
  background: #111517;
  z-index: -1;
  transition: 1s;
  content: "";
}

.btn::before {
  height: 50px;
  width: 320px;
}

.btn::after {
  width: 150px;
  height: 30px;
}

.btn:hover::before {
  width: 0px;
  background: #111517;
}

.btn:hover::after {
  height: 0px;
  background: #111517;
}

.btn:hover {
  background: #111517;
}
