@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.cdnfonts.com/css/bricolage-grotesque");
@import url("https://fonts.cdnfonts.com/css/roboto-condensed");

:root {
  --swiper-pagination-color: red;
  /* --swiper-pagination-bullet-size: 26px; */
  --swiper-pagination-bullet-width: 10px;
  --swiper-pagination-bullet-height: 10px;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.5;
  /* --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px; */
}

body {
  background: black;
  font-family: "Bricolage Grotesque", sans-serif;
}
.roboto {
  font-family: "Roboto Condensed", sans-serif;
}

@keyframes floatingAnimation {
  0%,
  100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -10px);
  }
}

.float {
  animation: floatingAnimation 3s infinite ease-in-out; /* Adjust the duration as needed */
}

@media (max-width: 400px) {
  .swiper-button-prev {
    display: none !important;
  }
  .swiper-button-next {
    display: none !important;
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: black !important;
  background: white;
  border-radius: 50%;
  padding: 2px;
  height: 35px !important;
  width: 35px !important;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 10px !important;
}
